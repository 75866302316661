<template>
  <div class="card">
    <div class="card-header">
      <h4 class="mb-0">
        {{ $t("shared.widgets.upcoming_activities.title") }}
      </h4>
    </div>

    <div class="card-body p-0">
      <div class="overflow-auto widget-events">
        <div v-if="!activities.length" class="px-4 py-3">
          <strong>
            {{
              $t("shared.widgets.upcoming_activities.missing_activities.title")
            }}
          </strong>

          <p>
            <em>
              {{
                $t(
                  "shared.widgets.upcoming_activities.missing_activities.description"
                )
              }}
            </em>
          </p>
        </div>

        <template v-for="activity in visibleActivities" v-else>
          <meeting-activity-row
            v-if="
              activity.owner_type == 'Meeting' &&
              activity.key === 'meeting.prepare_for_meeting'
            "
            :key="`meeting-activity-${activity.id}`"
            class="event-container py-2"
            :activity="activity"
          />

          <activity-row
            v-else
            :key="`activity-${activity.id}`"
            class="event-container py-2"
            :activity="activity"
          />
        </template>
      </div>

      <div v-if="activities.length > limit" class="card-footer text-center p-3">
        <be-button
          v-be-modal="'upcoming-activities-modal'"
          variant="link"
          class="text-muted"
        >
          {{
            $t("components.upcoming_activities.show_all", {
              count: activities.length,
            })
          }}
        </be-button>

        <be-modal
          id="upcoming-activities-modal"
          :title="$t('shared.widgets.upcoming_activities.title')"
          ok-only
          ok-variant="light"
          :ok-title="$t('buttons.titles.close')"
        >
          <div class="overflow-auto widget-events h-100">
            <activity-row
              v-for="activity in activities"
              :key="activity.id"
              class="event-container"
              :activity="activity"
            />
          </div>
        </be-modal>
      </div>
    </div>
  </div>
</template>

<script>
import ActivityRow from "./ActivityRow.vue";
import MeetingActivityRow from "./MeetingActivityRow.vue";

export default {
  components: {
    ActivityRow,
    MeetingActivityRow,
  },

  props: {
    activities: {
      type: Array,
      required: true,
    },

    limit: {
      type: Number,
      required: false,
      default: 10,
    },
  },

  computed: {
    visibleActivities() {
      return this.activities.slice(0, this.limit);
    },
  },
};
</script>
