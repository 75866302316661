<template>
  <div class="event px-3 py-2" :class="`event-${badgeClass()}`">
    <div>
      <span
        v-if="activity.due_at"
        class="badge text-uppercase"
        :class="`badge-${badgeClass()}`"
      >
        {{ $d(new Date(activity.due_at), dateFormat()) }}
      </span>

      <a :href="activity.link">
        <strong>
          {{
            $t(
              `public_activity_beta.short.${activity.type.toLowerCase()}.title`
            )
          }}
        </strong>
      </a>
    </div>

    <div v-if="!$currentCompany.id && activity.company">
      <a :href="url(`/companies/${activity.company.nanoid}`)" class="small">
        {{ activity.company.title }}
      </a>
    </div>

    <p v-dompurify-html="activity.description" class="mb-0" />
  </div>
</template>

<script>
import ActivityRowMixin from "./activity-row-mixin";

export default {
  mixins: [ActivityRowMixin],

  props: {
    activity: {
      type: Object,
      required: true,
    },
  },
};
</script>
