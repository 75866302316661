<template>
  <div class="mt-2">
    <template v-for="(group, key) in documentGroups" :key="key">
      <be-accordion>
        <be-accordion-item
          v-if="showAttachmentGroup(key)"
          :title="accordionTitle(group.key, null)"
          :open="!!countDocuments(key)"
          class="mb-2"
        >
          <template #header-right>
            <span class="badge badge-light badge-pill">
              {{ countDocuments(key) }}
            </span>
          </template>

          <div v-for="(doc, innerKey) in group.documents" :key="innerKey">
            <document-link
              :company-id="companyId"
              :document-id="doc.id"
              :filename="doc.filename"
              :title="doc.title"
            />
          </div>
        </be-accordion-item>

        <template v-if="key === 'ITEMS'">
          <be-accordion-item
            v-for="type in minutesTypes"
            :key="type"
            :title="accordionTitle(type, key)"
            class="mb-2"
          >
            <template #header-right>
              <span class="badge badge-light badge-pill">
                {{ countDocumentsFromType(type) }}
              </span>
            </template>

            <div
              v-for="(item, innerKey) in itemsFromType(type)"
              :key="innerKey"
            >
              <h6 class="m-0">
                {{ item.title }}
              </h6>

              <div
                v-for="(doc, innerInnerKey) in sortDocumentsByFilename(
                  item.documents
                )"
                :key="innerInnerKey"
                class="py-1 px-2"
              >
                <document-link
                  :company-id="companyId"
                  :document-id="doc.id"
                  :filename="doc.filename"
                  :title="doc.title"
                />
              </div>
            </div>
          </be-accordion-item>
        </template>
      </be-accordion>
    </template>
  </div>
</template>

<script>
import MaterialMixin from "@/mixins/meetings/material";

export default {
  mixins: [MaterialMixin],

  props: {
    meeting: {
      type: Object,
      required: true,
    },

    folder: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      documentGroups: {},

      documentGroupsTemplate: {
        PREVIOUS_MINUTES: {
          key: "previous_minutes",
          documents: [],
        },

        CURRENT_AGENDA: {
          key: "agenda",
          documents: [],
        },

        CURRENT_MINUTES: {
          key: "minutes",
          documents: [],
        },

        ITEMS: {
          key: "items",
          documents: [],
        },

        OTHER: {
          key: "other",
          documents: [],
        },
      },

      items: {
        minutes: [],
        agenda: [],
      },

      minutesTypes: ["agenda", "minutes"],
    };
  },

  computed: {
    agenda() {
      return this.getAgenda(this.meeting);
    },

    minutes() {
      return this.getMinutes(this.meeting);
    },

    folderDocuments() {
      return this.folder?.subtree_documents || this.folder?.documents || [];
    },

    companyId() {
      return this.meeting.company_id;
    },
  },

  watch: {
    folder: {
      handler: function () {
        this.groupDocuments();
      },

      deep: true,
    },

    agenda() {
      this.groupDocuments();
      this.getItems();
    },

    minutes() {
      this.groupDocuments();
      this.getItems();
    },
  },

  mounted() {
    this.fetchAgendaForMeeting(this.meeting);
    this.fetchMinutesForMeeting(this.meeting);

    this.groupDocuments();
    this.getItems();
  },

  methods: {
    showAttachmentGroup(key) {
      let show = true;

      if (key === "ITEMS") {
        show = false;
      }

      if (key === "OTHER") {
        if (this.countDocuments("OTHER") === 0) {
          show = false;
        }
      }

      return show;
    },

    accordionTitle(type, key) {
      if (key === "ITEMS") {
        if (type === "agenda") {
          return this.$t(
            "companies.meetings.file_list.group_titles.items.agenda"
          );
        } else if (type === "minutes") {
          return this.$t(
            "companies.meetings.file_list.group_titles.items.minutes"
          );
        }
      } else if (type === "previous_minutes") {
        return this.$t(
          "companies.meetings.file_list.group_titles.previous_minutes"
        );
      } else if (type === "agenda") {
        return this.$t("companies.meetings.file_list.group_titles.agenda");
      } else if (type === "minutes") {
        return this.$t("companies.meetings.file_list.group_titles.minutes");
      } else if (type === "other") {
        return this.$t("companies.meetings.file_list.group_titles.other");
      }

      return "";
    },

    async getItems() {
      if (!this.hasLoadedMaterials) {
        // Wait until everything is loaded
        return;
      }

      this.getAgendaItems();
      this.getMinutesItems();
    },

    async getAgendaItems() {
      if (!this.agenda?.id) {
        return;
      }

      try {
        const response = await axios.get(
          `${this.meeting.paths.base}/materials/${this.agenda.id}/items?document_list=true`
        );

        this.items.agenda = this.sortItemsByNumber(response.data);
      } catch (error) {
        this.handleError(error);
      }
    },

    async getMinutesItems() {
      if (!this.minutes?.id) {
        return;
      }

      try {
        const response = await axios.get(
          `${this.meeting.paths.base}/materials/${this.minutes.id}/items?document_list=true`
        );

        this.items.minutes = this.sortItemsByNumber(response.data);
      } catch (error) {
        this.handleError(error);
      }
    },

    sortItemsByNumber(items) {
      return items.sort((a, b) => a.number - b.number);
    },

    sortDocumentsByFilename(documents) {
      return documents.sort((a, b) => a.filename.localeCompare(b.filename));
    },

    itemsFromType(type) {
      let id = null;

      if (type === "minutes") {
        id = this.minutes.id;
      } else if (type === "agenda") {
        id = this.agenda.id;
      }

      return this.items[type].filter((item) => item.material_id === id);
    },

    countDocuments(key) {
      return this.documentGroups[key].documents.length;
    },

    countDocumentsFromType(type) {
      let total = 0;

      this.itemsFromType(type).forEach((item) => {
        total += item.documents.length;
      });

      return total;
    },

    groupDocuments() {
      if (!this.hasLoadedMaterials) {
        // Wait until everything is loaded
        return;
      }

      this.documentGroups = this.cloneDeep(this.documentGroupsTemplate);

      this.folderDocuments.forEach((doc) => {
        let group = "OTHER";

        if (!!doc.owner_id && !!doc.owner_type) {
          if (doc.owner_type === "Material") {
            if (doc.owner_id === this.agenda?.id) {
              group = "CURRENT_AGENDA";
            } else if (doc.owner_id === this.minutes?.id) {
              group = "CURRENT_MINUTES";
            } else {
              group = "PREVIOUS_MINUTES";
            }
          } else if (doc.owner_type === "Item") {
            group = "ITEMS";
          }
        }

        this.documentGroups[group].documents.push(doc);
      });
    },
  },
};
</script>
