<template>
  <template v-if="reports.length > 0">
    <be-table
      :items="reports"
      :fields="fields"
      sort-by="published_at"
      sort-desc
    >
      <template #company="{ item }">
        <be-link
          v-if="getCompanyNanoId(item.owner_id)"
          :href="
            url(`/`, {
              nanoid: getCompanyNanoId(item.owner_id),
            })
          "
        >
          {{ getCompanyTitle(item.owner_id) }}
        </be-link>

        <template v-else>
          {{ getCompanyTitle(item.owner_id) }}
        </template>
      </template>

      <template #title="{ item }">
        <be-link
          v-if="getCompanyNanoId(item.owner_id)"
          :href="
            url(`/reports/${item.id}`, {
              nanoid: getCompanyNanoId(item.owner_id),
            })
          "
        >
          {{ item.title }}
        </be-link>

        <template v-else>
          {{ item.title }}
        </template>
      </template>

      <template #icons="{ item }">
        <div class="text-muted">
          <i
            v-if="item.parent_report_id"
            v-be-tooltip="$t('models.report.tooltips.has_versions')"
            class="fal fa-copy fa-fw ml-1"
          />

          <i
            v-if="item.has_comments"
            v-be-tooltip="$t('models.report.tooltips.has_comments')"
            class="fal fa-comment fa-fw ml-1"
          />

          <i
            v-if="item.has_attachments"
            v-be-tooltip="$t('models.report.tooltips.has_attachments')"
            class="fal fa-paperclip fa-fw"
          />
        </div>
      </template>

      <template #period_end="{ item }">
        {{ $d(new Date(item.period_end), { format: "year_month" }) }}
      </template>

      <template #report_type="{ item }">
        {{
          item.report_type !== "general"
            ? $t(`models.report.types.${item.report_type}`)
            : ""
        }}
      </template>

      <template #published_at="{ item }">
        {{ $d(new Date(item.published_at), { format: "year_month" }) }}
      </template>
    </be-table>

    <p class="mt-2 text-muted small font-italic">
      {{ $t("components.home.home_reports.latest_reports_information") }}
    </p>
  </template>

  <be-alert v-else variant="info">
    {{ $t("components.home.home_reports.empty") }}
  </be-alert>
</template>

<script>
export default {
  props: {
    includeCompanyColumn: {
      type: Boolean,
      default: true,
    },

    reports: {
      type: Array,
      required: true,
    },
  },

  computed: {
    fields() {
      return [
        ...(this.includeCompanyColumn
          ? [
              {
                key: "company",
                label: this.$t("models.report.company_title"),
                class: "col-shrink",
                sortable: true,
              },
            ]
          : []),
        {
          key: "title",
          label: this.$t("activerecord.attributes.report.title"),
          sortable: true,
          class: "col-shrink col-md-auto",
        },
        {
          key: "icons",
          label: "",
          class: "col-shrink",
        },
        {
          key: "period_end",
          label: this.$t("activerecord.attributes.report.period_end"),
          class: "col-shrink",
          sortable: true,
        },
        {
          key: "report_type",
          label: this.$t("activerecord.attributes.report.report_type"),
          class: "col-shrink",
          sortable: true,
        },
        {
          key: "published_at",
          label: this.$t("activerecord.attributes.report.published_at"),
          class: "col-shrink",
          sortable: true,
        },
      ];
    },
  },

  methods: {
    getCompany(companyId) {
      return this.$store.getters["company/getById"](companyId);
    },

    getCompanyNanoId(companyId) {
      return this.getCompany(companyId)?.nanoid;
    },

    getCompanyTitle(companyId) {
      return this.getCompany(companyId)?.title;
    },
  },
};
</script>
